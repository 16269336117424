<script setup lang="ts">
    import type { BackgroundColorsPredefined, TeaserListItem } from '~/@types/cms';

    const { colorMapper } = useStyle();

    interface TeaserGridListProps {
        list: TeaserListItem[];
        display: 'grid_feature' | 'blog';
        title?: string;
        subtitle?: string;
        background?: BackgroundColorsPredefined;
    }
    withDefaults(defineProps<TeaserGridListProps>(), {
        background: 'default',
    });
</script>
<template>
    <div :class="[colorMapper[background], 'org-teaser-grid py-8 md:py-10', { 'text-white': background === 'black' }]">
        <lazy-mol-intro
            v-if="title || subtitle"
            :title="title"
            :description="subtitle"
            center />
        <atm-grid>
            <template
                v-for="(item, idx) in list"
                :key="item.id">
                <div
                    :class="{
                        'text-white': background === 'black',
                        'col-span-2 md:col-span-12': (idx === 0 && display === 'grid_feature') || (idx === 0 && display === 'blog'),
                        'text-center': idx === 0 && display === 'grid_feature',
                        'col-span-2 md:col-span-6': idx > 0 && display === 'grid_feature',
                        'col-span-2 md:col-span-6 lg:col-span-8': idx === 1 && display === 'blog',
                        'col-span-2 md:col-span-6 lg:col-span-4': idx > 1 && display === 'blog',
                    }">
                    <mol-teaser
                        :item="item"
                        :idx="idx"
                        :direction="idx === 0 && display === 'blog' ? 'horizontal' : 'vertical'" />
                </div>
            </template>
        </atm-grid>
    </div>
</template>
