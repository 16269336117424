<script setup lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import type { BackgroundColorsPredefined, TeaserListItem } from '~/@types/cms';
    import 'swiper/css';

    const { colorMapper } = useStyle();

    const { setMainSwiper, isBeginning, isEnd, setControls, swipeLeft, swipeRight, onSlideFocus } = useGallery();

    declare interface TeaserSwiperProps {
        items: TeaserListItem[];
        perView: number;
        link?: Link;
        title?: string;
        subtitle?: string;
        background?: BackgroundColorsPredefined;
    }
    const props = withDefaults(defineProps<TeaserSwiperProps>(), {
        background: 'default',
    });

    const md = screens('md');
    const lg = screens('lg');
</script>
<template>
    <div
        v-if="props.items && props.items.length > 0"
        :class="[
            colorMapper[background],
            'org-teaser-swiper relative items-center overflow-hidden py-8 md:py-10',
            { 'text-white': background === 'black' },
        ]">
        <lazy-mol-intro
            v-if="title || subtitle"
            :title="title"
            :description="subtitle"
            center />
        <atm-grid>
            <div class="col-span-2 md:col-span-12">
                <nuxt-error-boundary @error="() => {}">
                    <swiper
                        class="relative !overflow-visible"
                        :slides-per-view="1"
                        :breakpoints="{
                            [md]: {
                                slidesPerView: 2,
                            },
                            [lg]: {
                                slidesPerView: props.perView || 3,
                            },
                        }"
                        :centered-slides="items.length === 1"
                        :space-between="16"
                        @swiper="setMainSwiper"
                        @slide-change="setControls">
                        <swiper-slide
                            v-for="(slide, index) in props.items"
                            :key="slide.id"
                            @focusin="() => onSlideFocus(index)">
                            <mol-teaser-card
                                :class="[perView === 3 ? 'aspect-3/4' : 'aspect-4/3']"
                                :item="slide"
                                :idx="index" />
                        </swiper-slide>
                        <mol-swiper-navigation
                            :is-beginning="isBeginning"
                            :is-end="isEnd"
                            :swipe-left="swipeLeft"
                            :swipe-right="swipeRight"
                            :link="link"
                            theme="wider" />
                    </swiper>
                </nuxt-error-boundary>
            </div>
        </atm-grid>
    </div>
</template>
