<script setup lang="ts">
    import type { TeaserListItem } from '~/@types/cms';

    interface TeaserCardProps {
        item: TeaserListItem;
        display?: 'feature' | 'content' | 'text';
        idx: number;
    }

    const props = defineProps<TeaserCardProps>();

    const display = computed(() => {
        return props.display ? props.display : props.item?.display || 'featured';
    });
</script>
<template>
    <nuxt-link
        :to="$helpers.generateLocalePath(item?.url)"
        class="mol-teaser-card relative flex flex-col overflow-hidden rounded-2xl"
        :class="{
            'justify-end': display === 'feature',
            'items-center justify-center border border-woom-grey-mid p-4': display === 'text',
        }">
        <template v-if="display === 'feature'">
            <div class="absolute bottom-0 left-0 right-0 top-0 z-0">
                <atm-image
                    :data="item.hero"
                    sizes="100vh md:50vw lg:592px"
                    preset="2to3"
                    cover />
            </div>
            <div class="relative bg-gradient-to-t from-black/80 to-transparent p-4 pt-8 text-white">
                <lazy-atm-heading
                    v-if="item.title"
                    level="h4">
                    <renderer-html :content="woomTextFormat(item.title)" />
                </lazy-atm-heading>
                <div v-if="item.subtitle">
                    <renderer-html :content="item.subtitle" />
                </div>
            </div>
        </template>
        <template v-else-if="display === 'content'">
            <atm-image :data="item.hero" />
            <!-- TODO: check why we need to wrap in a div -->
            <div v-if="item.title">
                <lazy-atm-heading
                    class="mt-3"
                    level="h4">
                    <renderer-html :content="woomTextFormat(item.title)" />
                </lazy-atm-heading>
            </div>
        </template>
        <template v-else>
            <div v-if="item.title">
                <lazy-atm-heading level="h4">
                    <renderer-html :content="woomTextFormat(item.title)" />
                </lazy-atm-heading>
            </div>
        </template>
    </nuxt-link>
</template>
