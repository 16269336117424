<script setup lang="ts">
    import type { HeroData, TeaserContainerData, TeaserListItem, Asset, AuthorData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';
    import type { TeaserQueryVariables } from '~/graphql/generated';
    const appStore = useAppStore();
    const { isoLocale, locale, t } = useLocales();

    /**
     * Teaser Widget Container
     * loads a set of teasers and renders them according to the style settings
     * this component should manage the loading of the data and select the correct renderer
     */

    const props = defineProps<IWidgetProps<TeaserContainerData>>();
    const { data, getValueSequence, resolveUrlLink, resolveAssetSrc } = useWidget(props);

    // console.log('TeaserWidgetContainer', props);

    const comps = computed(() => data.value.components || []);
    // const seq = computed(() => data.value.sequence || []);
    const total = computed(() => data.total || 0);

    const limit = computed(() => total.value - comps.value.length);

    const filters = computed(() => {
        const filters: {
            name: string;
            values: any;
            any: any;
        }[] = [];

        const filterNames = {
            topic: 'topic',
            product: 'product_category',
            site: 'site',
            type: 'content_type',
        };

        if (data.search) {
            const keys = Object.keys(data.search);
            for (const k of keys) {
                const s = (data.search as any)[k];
                if (typeof s === 'object' && s.list && s.list.length > 0) {
                    filters.push({
                        name: (filterNames as any)[k],
                        values: s.list,
                        any: !(s.any && s.any === 'all'),
                    });
                }
            }
        }
        return filters;
    });

    const { queryParams } = usePreview();

    const queryVariables = computed<TeaserQueryVariables>(() => {
        // console.log('fetch limit', limit.value);
        return {
            locale: locale.value, // 'de_CH',
            id: appStore.pageCache?.id || '',
            references: props.data.value.references,
            ...queryParams.value,
            filters: {
                limit: limit.value,
                filters: filters.value,
                sort: data.search?.sort ?? 'default',
            },
        };
    });

    const display = computed(() => {
        return data.style?.display;
    });
    const title = computed(() => {
        return data.intro?.title;
    });
    const subtitle = computed(() => {
        return data.intro?.subtitle;
    });

    const fetchTeaserData = async (variables: any) => {
        const hash = md5(`${props.data.codename}-${JSON.stringify(variables)}`);
        if (!hash) {
            console.log(`no cache key available for TeaserWidgetContainer ${JSON.stringify(variables)}`);
            return {
                pending: ref(false),
                status: ref('success'),
                error: ref(),
                data: [],
            };
        }
        return await useAsyncData<TeaserListItem[]>(hash, async () => {
            // 1. check if cached data is available
            const { data: cachedData } = useNuxtData(hash);
            if (cachedData?.value) return unref(cachedData);

            const items: TeaserListItem[] = [];
            // 2. prep static data
            const staticList = getValueSequence(data.value);
            for (const slide of staticList) {
                const { url, label } = resolveUrlLink(slide.link, appStore.getReference);
                items.push({
                    id: slide.id,
                    title: slide.title,
                    hero: slide.hero,
                    tag: undefined,
                    date: undefined,
                    author: undefined,
                    display: !slide.hero ? 'text' : 'feature',
                    subtitle: slide.subtitle,
                    url,
                    urlLabel: label,
                });
            }

            // 3. if no linked teasers are needed, return items
            if (limit.value <= 0) return items;

            // 4. enrich with teasers
            // No try catch needed, handled by nuxt useAsyncData
            const result = await $fetch('/api/teaser', {
                query: variables,
            });

            if (result?.data?.fetchTeaser?.teaser) {
                const fetchAuthor = (author_ref: string | null | undefined): AuthorData | undefined => {
                    if (!author_ref) return;

                    const author = result.data.fetchTeaser.refs?.authors.find((a: any) => a.id === author_ref);
                    if (!author) return;

                    return {
                        name: author.name,
                        image: resolveAssetSrc(author.portrait),
                    };
                };
                const fetchTag = (tag_ref: string | null | undefined): any | undefined => {
                    if (!tag_ref) return;

                    const tag = result.data.fetchTeaser.refs?.tags.find((a: any) => a.id === tag_ref);
                    return tag?.title;
                };

                for (const slide of result.data.fetchTeaser.teaser) {
                    items.push({
                        id: slide.id,
                        title: slide.title || '',
                        subtitle: slide.subtitle || undefined,
                        hero: slide.hero as Asset,
                        display: !slide.hero ? 'text' : 'feature',
                        tag: fetchTag(slide.tag_ref),
                        author: fetchAuthor(slide.author_ref),
                        // @ts-ignore - missing in the generated GQL type
                        date: slide.date ? new Intl.DateTimeFormat(isoLocale.value).format(Date.parse(slide.date)) : undefined,
                        // @ts-ignore - missing in the generated GQL type
                        url: slide.url?.url,
                    });
                }
            }
            return items;
        });
    };

    const { data: teaserList } = await fetchTeaserData(unref(queryVariables.value));

    const toHeroData = (teaserList: TeaserListItem[]) => {
        const d: HeroData[] = [];
        if (Array.isArray(teaserList)) {
            for (const item of teaserList) {
                d.push({
                    ...item,
                    codename: item.id,
                    types: ['Widget', 'BackgroundCover'],
                    language: 'de',
                    link: {
                        type: 'url',
                        label: t('cta.readMore'),
                        data: {
                            url: item.url,
                        },
                    },
                });
            }
        }
        return d;
    };
</script>
<template>
    <org-teaser-swiper
        v-if="teaserList && display === 'gallery'"
        :items="teaserList"
        :per-view="3"
        :title="title"
        :subtitle="subtitle"
        background="grey"
        class="cms-teaser-widget-container" />
    <org-teaser-swiper
        v-else-if="teaserList && display === 'galleryxl'"
        :items="teaserList"
        :per-view="2"
        :title="title"
        :subtitle="subtitle"
        class="cms-teaser-widget-container" />
    <org-hero-slider
        v-else-if="teaserList && display === 'header'"
        :data="toHeroData(teaserList)"
        :title="title"
        :subtitle="subtitle"
        class="cms-teaser-widget-container" />
    <org-teaser-grid
        v-else-if="teaserList && display === 'grid_feature'"
        :list="teaserList"
        display="grid_feature"
        :title="title"
        :subtitle="subtitle"
        class="cms-teaser-widget-container" />
    <org-teaser-grid
        v-else-if="teaserList && display === 'blog'"
        :title="title"
        :subtitle="subtitle"
        :list="teaserList"
        display="blog"
        background="grey"
        class="cms-teaser-widget-container" />
</template>
