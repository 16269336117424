<script setup lang="ts">
    import type { TeaserListItem } from '~/@types/cms';
    const { t } = useLocales();

    interface TeaserCardProps {
        item: TeaserListItem;
        idx: number;
        direction: 'vertical' | 'horizontal';
    }
    const props = defineProps<TeaserCardProps>();

    const tag = computed(() => {
        if (!props.item.tag) return;
        let tag = props.item.tag;
        if (props.item.date) tag += ` - ${props.item.date}`;
        return tag;
    });
</script>
<template>
    <div
        class="mol-teaser relative mb-6 flex h-full gap-4 max-lg:flex-col lg:mb-8"
        :class="{ 'flex-col': direction === 'vertical' }">
        <nuxt-link
            :to="$helpers.generateLocalePath(item.url)"
            :class="[{ 'lg:max-w-[55%]': direction === 'horizontal' }, 'w-full rounded-md']">
            <atm-image
                :data="item.hero"
                sizes="100wv md:1264px lg:1400px"
                class="w-full rounded-md" />
        </nuxt-link>

        <div :class="[{ 'justify-center': direction === 'horizontal' }, 'relative flex flex-col gap-3 lg:mx-4']">
            <span
                v-if="tag"
                class="font-mono">
                {{ tag }}
            </span>
            <atm-heading
                v-if="item.title"
                level="h4">
                <renderer-html :content="woomTextFormat(item.title)" />
            </atm-heading>
            <div v-if="item.subtitle">
                <renderer-html :content="woomTextFormat(item.subtitle)" />
            </div>

            <span v-if="item.author">
                <span class="inline-flex items-center gap-2">
                    <nuxt-img
                        :src="item.author?.image"
                        class="inline h-8 w-8 rounded-full"
                        width="100" />
                    <span class="text-sm">{{ item.author.name }}</span>
                </span>
            </span>

            <div>
                <atm-button
                    v-if="item.url"
                    class="mt-1"
                    styling="solid-secondary"
                    kind="link"
                    :to="item.url">
                    {{ item.urlLabel || t('cta.readMore') }}
                </atm-button>
            </div>
        </div>
    </div>
</template>
